// eslint-disable-next-line import/prefer-default-export
export const listSelections = /* GraphQL */ `
    query ListSelections(
        $id: ID
        $filter: ModelSelectionFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSelections(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                ensemble {
                    id
                    name
                }
                application {
                    id
                    instrument {
                        id
                        name
                    }
                    teacher {
                        id
                        name {
                            first
                            last
                        }
                    }
                    student {
                        id
                        name {
                            first
                            last
                            preferred
                        }
                        address {
                            line1
                            line2
                            city
                            state
                            zip
                        }
                        school {
                            id
                            name {
                                legal
                                popular
                            }
                        }
                        
                    }
                }
            }
            nextToken
        }
    }
`;

export const listSchools = /* GraphQL */ `
    query ListSchools(
        $id: ID
        $filter: ModelSchoolFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSchools(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name {
                    legal
                    popular
                }
                address {
                    line1
                    line2
                    city
                    state
                    zip
                }
                staff {
                    type
                    title
                    name {
                        first
                        last
                    }
                }
            }
            nextToken
        }
    }
`;

export const listSelectionsForSchools = /* GraphQL */ `
    query ListSelections(
        $id: ID
        $filter: ModelSelectionFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSelections(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                application {
                    id
                    student {
                        id
                        schoolID
                    }
                }
            }
            nextToken
        }
    }
`;
